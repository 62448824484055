var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Grid/Grid.jinja"] = require( "front/components/Grid/Grid.jinja" );
dependencies["front/components/Button/Button.jinja"] = require( "front/components/Button/Button.jinja" );
dependencies["front/components/ButtonIcon/ButtonIcon.jinja"] = require( "front/components/ButtonIcon/ButtonIcon.jinja" );
dependencies["front/components/QualityForm/QualityForm.jinja"] = require( "front/components/QualityForm/QualityForm.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/Contact/Contact.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/Contact/Contact.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Contact/Contact.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n";
env.getTemplate("front/components/Grid/Grid.jinja", false, "assets/app/front/pages/Contact/Contact.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "Grid")) {
var t_10 = t_7.Grid;
} else {
cb(new Error("cannot import 'Grid'")); return;
}
context.setVariable("Grid", t_10);
output += "\n";
env.getTemplate("front/components/Button/Button.jinja", false, "assets/app/front/pages/Contact/Contact.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "Button")) {
var t_14 = t_11.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_14);
output += "\n";
env.getTemplate("front/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/front/pages/Contact/Contact.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "ButtonIcon")) {
var t_18 = t_15.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_18);
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Contact/Contact.jinja", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
if(Object.prototype.hasOwnProperty.call(t_19, "svgSprite")) {
var t_22 = t_19.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_22);
output += "\n\n";
env.getTemplate("front/components/QualityForm/QualityForm.jinja", false, "assets/app/front/pages/Contact/Contact.jinja", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
t_23.getExported(function(t_25,t_23) {
if(t_25) { cb(t_25); return; }
if(Object.prototype.hasOwnProperty.call(t_23, "QualityForm")) {
var t_26 = t_23.QualityForm;
} else {
cb(new Error("cannot import 'QualityForm'")); return;
}
context.setVariable("QualityForm", t_26);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
output += t_27;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_30,t_29) {
if(t_30) { cb(t_30); return; }
output += t_29;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_32,t_31) {
if(t_32) { cb(t_32); return; }
output += t_31;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_34,t_33) {
if(t_34) { cb(t_34); return; }
output += t_33;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_36,t_35) {
if(t_36) { cb(t_36); return; }
output += t_35;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("libs"))(env, context, frame, runtime, function(t_38,t_37) {
if(t_38) { cb(t_38); return; }
output += t_37;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 10, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 13, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--contact","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "ContactPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 17;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Contact\">\n    <div class=\"Contact-map\" id=\"Contact-map\" ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon")) {
output += "data-center=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lat"), env.opts.autoescape);
output += "\"";
;
}
output += "></div>\n\n    <div class=\"Contact-contentWrapper\">\n        <div class=\"Contact-contentMain\">\n            <h2 class=\"Contact-title\">";
output += runtime.suppressValue((lineno = 23, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
output += "</h2>\n\n            <div class=\"Contact-content\">\n                <ul class=\"Contact-contentGrid\">\n                    <li class=\"Contact-contentGridItem\">\n                        <a class=\"Contact-contentGridLink Contact-contentGridLink--excursion\" href=\"";
output += runtime.suppressValue((lineno = 28, colno = 106, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:excursion"])), env.opts.autoescape);
output += "\">\n                            <span>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 29, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Узнать про экскурсию"]))), env.opts.autoescape);
output += "</span>\n                        </a>\n                    </li>\n                    <li class=\"Contact-contentGridItem\">\n                        <a class=\"Contact-contentGridLink Contact-contentGridLink--partner\" href=\"";
output += runtime.suppressValue((lineno = 33, colno = 104, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-information"])), env.opts.autoescape);
output += "\">\n                            <span>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 34, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стать партнером"]))), env.opts.autoescape);
output += "</span>\n                        </a>\n                    </li>\n                    <li class=\"Contact-contentGridItem\">\n                        <a class=\"Contact-contentGridLink Contact-contentGridLink--quality js-openQualityForm\" href=\"#discuss-quality\">\n                            <span>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 39, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Поделиться мнением о&nbsp;нашей продукции"]))), env.opts.autoescape);
output += "</span>\n                        </a>\n                    </li>\n                </ul>\n\n                <div class=\"Contact-contentInfo\">\n                    <div class=\"Contact-contentInfoSection\">\n                        <div class=\"Contact-contentInfoSectionTitle\">";
output += runtime.suppressValue((lineno = 46, colno = 73, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Остались вопросы?"])), env.opts.autoescape);
output += "</div>\n                        <div class=\"Contact-contentInfoSectionText\">\n                            <p>";
output += runtime.suppressValue((lineno = 48, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Звоните"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"phone"), env.opts.autoescape);
output += "</p>\n                            <p>";
output += runtime.suppressValue((lineno = 49, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Пишите"])), env.opts.autoescape);
output += ": <a class=\"Contact-link\" href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"email"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"email"), env.opts.autoescape);
output += "</a></p>\n                        </div>\n                    </div>\n\n                    <div class=\"Contact-contentInfoSection\">\n                        <div class=\"Contact-contentInfoSectionTitle\">";
output += runtime.suppressValue((lineno = 54, colno = 73, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Адрес"])), env.opts.autoescape);
output += "</div>\n                        <div class=\"Contact-contentInfoSectionText\">\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"address"), env.opts.autoescape);
output += "\n                        </div>\n                        <a\n                            class=\"Button Button--grow Button--small Button--transparent Contact-button\"\n                            href=\"http://maps.yandex.ru/?mode=routes&sll=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, (env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon")),",",".") + "," + env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lat")),",","."))), env.opts.autoescape);
output += "&rtext=~";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, (env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lat")),",",".") + "," + env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon")),",","."))), env.opts.autoescape);
output += "&rtt=auto&stext=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"address")), env.opts.autoescape);
output += "&text=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"address")), env.opts.autoescape);
output += "\"\n                            target=\"_blank\"\n                            rel=\"nofollow noopener\"\n                        >\n                            ";
output += runtime.suppressValue((lineno = 64, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Маршрут"])), env.opts.autoescape);
output += "\n                        </a>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"Contact-qualityFormWrapper\">\n            <div class=\"Contact-qualityFormClose\">";
output += runtime.suppressValue((lineno = 72, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ButtonIcon"), "ButtonIcon", context, ["js-closeQualityForm"])), env.opts.autoescape);
output += "</div>\n            <h3 class=\"Contact-title\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 73, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Поделиться мнением о&nbsp;нашей продукции"]))), env.opts.autoescape);
output += "</h2>\n            <div class=\"Contact-qualityFormText\">\n                <p>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 75, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Ваши сообщения о&nbsp;качестве нашей продукции помогают нам становиться еще лучше. Все поля обязательны для заполнения."]))), env.opts.autoescape);
output += "</p>\n            </div>\n\n            <div class=\"Contact-qualityForm\">\n                ";
output += runtime.suppressValue((lineno = 79, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "QualityForm"), "QualityForm", context, [runtime.makeKeywordArgs({"lang": runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE"),"caller": (function (){var macro_t_39 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_40 = "";;
frame = frame.pop();
return new runtime.SafeString(t_40);
});
return macro_t_39;})()})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_libs(env, context, frame, runtime, cb) {
var lineno = 86;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "libs", b_libs, frame, runtime, function(t_41,hole_146) {
if(t_41) { cb(t_41); return; }
hole_146 = runtime.markSafe(hole_146);
output += "\n    ";
output += runtime.suppressValue(hole_146, env.opts.autoescape);
output += "\n    <script src='https://api.tiles.mapbox.com/mapbox-gl-js/v0.47.0/mapbox-gl.js'></script>\n    <link href='https://api.tiles.mapbox.com/mapbox-gl-js/v0.47.0/mapbox-gl.css' rel='stylesheet' />\n\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_content: b_content,
b_libs: b_libs,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/Contact/Contact.jinja"] , dependencies)