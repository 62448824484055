var Velocity = require('velocity-animate');
var basicScroll = require('basicscroll');
var Stickyfill = require('stickyfilljs');

var Page = require('front/pages/Page/Page');
var MenuLeft = require('front/components/MenuLeft/MenuLeft');
var TopNav = require('front/components/TopNav/TopNav');
var Story = require('front/components/Story/Story');

require('./History.less');

module.exports = Page.extend({
    template: require('./History.jinja'),

    el: '.History',

    events: {
        'click .History-yearLink': 'onClickYear'
    },

    title: window.gettext('Компания') + ': ' + window.gettext('История'),

    initialize: function (options) {
        var currentYear = 2024;

        this.menuLeft = new MenuLeft();

        this.stories = this.$('.Story').map(function (index, el) {
            return new Story({
                el: $(el)
            });
        });

        this.years = this.$('.History-listBlock')
            .add('.History-launchBlock')
            .map(function (index, el) {
                var $el = $(el);
                var $news = $el.find('.History-news,.History-launch');
                var $year = $el.find('.History-year');
                var year = parseInt($year.attr('id'), 10);
                var $yearLink = $('.History-yearLink[data-year="' + year + '"]');
                var $years = $year.add($yearLink);
                var isActive = false;

                var instance = basicScroll.create({
                    elem: $news[0],
                    direct: true,
                    from: 'top-bottom',
                    to: 'bottom-bottom',
                    inside: function (instance, percentage, props) {
                        if (!isActive) {
                            $years.addClass('isActive');
                            isActive = true;
                        }
                    }.bind(this),
                    outside: function (instance, percentage, props) {
                        if (year === 2006 && percentage > 100) {
                            $years.addClass('isActive');
                            isActive = true;
                        } else if (year === currentYear && percentage < 0) {
                            $years.addClass('isActive');
                            isActive = true;
                        } else {
                            if (isActive) {
                                $years.removeClass('isActive');
                                isActive = false;
                            }
                        }
                    }.bind(this)
                });

                instance.start();

                return instance;
            });

        Stickyfill.add(this.$('.History-years'));
        Page.prototype.initialize.call(this, options);
    },

    onClickYear: function (e) {
        e.preventDefault();

        var year = $(e.target).attr('data-year');
        var $yearEl = $('#' + year);

        app.els.$htmlBody.animate({
            scrollTop: $yearEl.offset().top
        });
    }
});
